<!-- guage -->
<template>
    <div class="gauge">
        <div class="top-title">
            <img src="../assets/download.png" alt="">
            <div>{{ title }}</div>
        </div>
        <div ref="chartDom" class="gaugeCharts">
        </div>

    </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance, inject } from 'vue'
import { useStore } from 'vuex'
const props = defineProps({
    options: {
        type: Object,
        required: true,
    },
    value: {
        type: Number,
        default: 0,
    },
    title: {
        type: String,
        default: "标题",
    },
    width: {
        type: String,
        default: "100%",
    },
    height: {
        type: String,
        default: "100%",
    },
})
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()
const uuid = ref(null)
const chartBox = ref(null)
const chartDom = ref(null)
let echarts = inject("echarts"); // 主要
const getUuid = () => {
    return new Date().getTime()
}
uuid.value = getUuid()
// 基本柱形图
const change = () => {
    //准备实例
    const options = {
        text: '组织“团结奋斗向未来”统一战线联合行动运动会',
        series: [
            {

                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                min: 0,
                max: 100,
                splitNumber: 2,
                itemStyle: {
                    color: '#58D9F9',
                    shadowColor: 'rgba(0,138,255,0.45)',
                    shadowBlur: 10,
                    shadowOffsetX: 2,
                    shadowOffsetY: 2
                },
                progress: {
                    show: true,
                    roundCap: true,
                    width: 10
                },
                pointer: {
                    icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                    length: '50%',
                    width: 16,
                    offsetCenter: [0, '5%']
                },
                axisLine: {
                    roundCap: true,
                    lineStyle: {
                        width: 10
                    }
                },
                axisTick: {
                    splitNumber: 2,
                    lineStyle: {
                        width: 2,
                        color: '#999'
                    }
                },
                splitLine: {
                    length: 6,
                    lineStyle: {
                        width: 3,
                        color: '#999'
                    }
                },
                axisLabel: {
                    distance: 30,
                    color: '#999',
                    fontSize: 20
                },

                detail: {
                    fontSize: 20,
                    color: '#fff',
                    show: true,
                    width: '40%',
                    lineHeight: 40,
                    height: 20,
                    offsetCenter: [0, '20%'],
                    valueAnimation: true,
                    formatter: function (value) {
                        return '执行进度';
                    },
                    rich: {
                        value: {
                            fontSize: 30,
                            fontWeight: 'bolder',
                            color: '#fff'
                        },
                        unit: {
                            fontSize: 20,
                            color: '#999',
                            padding: [0, 0, -20, 10]
                        }
                    }
                },
                data: [
                    {
                        value: props.value
                    }
                ]
            }
        ]

    }
    chartBox.value = echarts.init(chartDom.value);
    chartBox.value.setOption(options, true);
    // 根据页面大小自动响应图表大小
    window.addEventListener("resize", function () {
        chartBox.value.resize();
    });
}

onMounted(() => {
    change()
    // console.log(props.options);

})
</script>
<style lang='less' scoped>
.gauge {
    .top-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;

        img {
            width: 10px;
            height: 20px;
            margin-right: 12px;
        }

        div {
            font-family: PangMenZhengDao;
            font-size: 24px;
            color: #fff;
            text-align: center;
            text-shadow: 0 0 8px #0075ff;
            font-weight: 400;
        }
    }

    .gaugeCharts {
        height: calc(100% - 80px);
    }
}
</style>