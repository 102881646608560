import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import echarts from './utils/echarts';
import 'lib-flexible/flexible'
createApp(App)
.use(store)
.use(router)
.use(ElementPlus,{locale: zhCn,})
.use(echarts)
.mount('#app')
