<!-- category -->
<template>
    <div class="category">
        <div class="top-title">
            <img src="../assets/download.png" alt="">
            <div>{{ title }}</div>
        </div>
        <div ref="chartDom" class="categoryCharts">
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance, inject } from 'vue'
import { useStore } from 'vuex'
const props = defineProps({
    options: {
        type: Object,
        required: true,
    },
    className: {
        type: String,
        default: "chart",
    },
    title: {
        type: String,
        default: "标题",
    },
    width: {
        type: String,
        default: "100%",
    },
    height: {
        type: String,
        default: "100%",
    },
})
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()
const uuid = ref(null)
const chartBox = ref(null)
const chartDom = ref(null)
let echarts = inject("echarts"); // 主要
const getUuid = () => {
    return new Date().getTime()
}
uuid.value = getUuid()
// 基本柱形图
const change = () => {
    //准备实例
    const options = {
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [150, 230, 224, 218, 135, 147, 260],
                type: 'line'
            }
        ]
    };
    chartBox.value = echarts.init(chartDom.value);
    chartBox.value.setOption(options, true);
    // 根据页面大小自动响应图表大小
    window.addEventListener("resize", function () {
        // chartBox.value.resize();
    });
}

onMounted(() => {
    change()
    // console.log(props.options);

})
</script>
<style lang='less' scoped>
.category {
    width: 100%;
    height: 100%;
    .top-title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;

        img {
            width: 10px;
            height: 20px;
            margin-right: 12px;
        }

        div {
            font-family: PangMenZhengDao;
            font-size: 24px;
            color: #fff;
            text-align: center;
            text-shadow: 0 0 8px #0075ff;
            font-weight: 400;
        }
    }

    .categoryCharts {
        height: 80%;
    }
}
</style>