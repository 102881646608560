//模块 接口
import http from '../utils/request'
export default {
    getAllActionDoneByDivisionCode(query) {
        //获取用户信息接口
        return http({
            method: 'get',
            url: "front/bigScreen/getAllActionDoneByDivisionCode",
            params: query
        })
    },
    getAllLeadDeptDoneByDivisionCode(query) {
        //获取用户信息接口
        return http({
            method: 'get',
            url: "front/bigScreen/getAllLeadDeptDoneByDivisionCode",
            params: query
        })
    },
    // 弹窗接口信息
    getIndexUnitByDivisionCodeAndDeptId(query) {
        return http({
            method: 'get',
            url: "front/bigScreen/getIndexUnitByDivisionCodeAndDeptId",
            params: query
        })
    },
}
