<!--StatusTips  -->
<template>
    <div class='StatusTips'>
        <div class="main">
            <div class="main-title">
                <span>
                    {{ props.name }}
                </span>
            </div>
            <div class="StatusTipsCharts">
                <div class="StatusTipsCharts-title">
                    <img src="../../assets/download.png" alt="">
                    <div>{{ title }}</div>
                </div>
                <div class="StatusTipsCharts-main">
                    <el-result class="el-result" :class="[OP ? 'p' : 'el-result']" icon="success" title="已完成">
                    </el-result>
                    <el-result class="el-result" :class="[OP ? 'el-result' : 'p']" icon="warning" title="未完成"> </el-result>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import gauge from '../../components/gauge.vue'
import taskExecution from '../taskExecution'
import inputOption from '../../components/options'
import category from '../../components/category'
import StatusTips from '../MiddleCharts/StatusTips.vue'
const props = defineProps({
    data: Object,
    index: {
        type: Number,
    },
    name: {
        type: String,
    },
    title: {
        type: String,
        default: "标题",
    },
})
const componentNames = ref([gauge, taskExecution, StatusTips])
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()

const num = [{
    name: '组件1',
    value: 10,
}, {
    name: '组件2',
    value: 11,
}, {
    name: '组件3',
    value: 20,
}, {
    name: '组件4',
    value: 30,
}, {
    name: '组件5',
    value: 23,
}, {
    name: '组件6',
    value: 54,
}, {
    name: '组件7',
    value: 88,
}]
onMounted(() => {
})

</script>
<style lang='less' scoped>
.StatusTips {
    height: 1080px;
    display: flex;
    align-items: center;
    justify-content: center;

    .main {
        width: 100%;
        height: 80%;
        background-image: linear-gradient(#0c2968, #0c296800);
        border: 1px #0f317e solid;
        border-radius: 2% 2% 0 0;
        box-shadow: 0px 0px 10px 0px #0075ff;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;

        .main-title {
            width: 100%;
            margin: 0, 10px;
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-family: PangMenZhengDao;
                color: #CFF0FF;
                font-size: 42px;
                text-shadow: 0 0 8px #0075ff;
            }
        }

        .StatusTipsCharts {

            // height: calc(100% - 80px);
            width: 100%;
            .StatusTipsCharts-title {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 80px;

                img {
                    width: 10px;
                    height: 20px;
                    margin-right: 12px;
                }

                div {
                    font-family: PangMenZhengDao;
                    font-size: 24px;
                    color: #fff;
                    text-align: center;
                    text-shadow: 0 0 8px #0075ff;
                    font-weight: 400;
                }
            }

            .StatusTipsCharts-main {
                display: flex;
                justify-content: center;
                align-items: center;

                .el-result {
                    :deep(.icon-warning) {
                        color: #FF5252
                    }

                    :deep(.el-result__title) {
                        margin-top: 0;

                        p {
                            font-family: YouSheBiaoTiHei;
                            font-size: 24px;
                            color: #FFFFFF;
                        }
                    }

                }

                .p {
                    opacity: 0.3;
                }
            }

        }
    }

    .main::-webkit-scrollbar {
        display: none;
    }

}
</style>

















































