<!--  RightListCon-->
<template>
    <div class='RightListCon'>
        <div class="RightListCon-item1" :title=data[props.index].name>{{ data[props.index].name }}</div>
        <div class="RightListCon-item2" title="指标数">{{ data[props.index].actionNumber }}</div>
        <div class="RightListCon-item3">{{ data[props.index].doneNumber }}/{{ data[props.index].totalNumber }}</div>
    </div>
</template>
<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import RightConDialog from './RightConDialog.vue'
const props = defineProps({
    data: Object,
    index: {
        default: 0,
        type: Number
    },

})

let RightConDialogVisible = ref(false)
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()
onMounted(() => {
    console.log(props);
})
</script>
<style lang='less' scoped>
.RightListCon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(142, 201, 255, .05);
    height: 50px;
    cursor: pointer;
    line-height: 40px;
    margin-bottom: 4px;
    padding: 5px 8px;
    box-sizing: border-box;

    .RightListCon-item1 {
        padding-Right: 13px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 17px;
        color: #FFFFFF;
        width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }


    .RightListCon-item2 {
        width: 25%;
        font-size: 20px;
        color: #cff0ff;
        font-weight: 400;
        text-align: left;
    }

    .RightListCon-item2:hover {
        // width: auto;
    }

    .RightListCon-item3 {
        width: 25%;
        height: 40px;
        text-align: right;
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #f9ad25;
        text-align: center;
        font-weight: 400;
    }
}
</style>