<!--  LeftListCon-->
<template>
    <div class='LeftListCon'>
        <div class="LeftListCon-item" v-if="index <= 2">{{ index + 1 }}</div>
        <div class="LeftListCon-item1" v-if="index > 2">{{ index + 1 }}</div>
        <div class="LeftListCon-item2" :title= data[props.index].name>{{ data[props.index].name }}</div>
        <div class="LeftListCon-item3">{{ data[props.index].doneNumber }}/{{ data[props.index].totalNumber }}</div>
    </div>
</template>
<script setup>
import { type } from 'os';
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
const props = defineProps({
    data: Object,
    index: {
        default: 0,
        type: Number
    },
})
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()
const LeftListConData = reactive({})
onMounted(() => {
    // console.log(props.data);
    LeftListConData.value = props.data

})
</script>
<style lang='less' scoped>
.LeftListCon {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: rgba(142, 201, 255, .05);
    height: 50px;
    cursor: pointer;
    line-height: 40px;
    margin-bottom: 4px;
    padding: 5px 8px;
    box-sizing: border-box;

    .LeftListCon-item,
    .LeftListCon-item1 {
        width: 22%;
        padding-left: 13px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 17px;
        color: #FFFFFF;

    }

    .LeftListCon-item {
        background: url(../../assets/icon_sort.png) no-repeat;
        background-size: auto 70%;
        background-position: 5px;
    }

    .LeftListCon-item2 {
        width: 60%;
        font-size: 20px;
        color: #cff0ff;
        text-align: left;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }



    .LeftListCon-item3 {
        width: 18%;
        height: 40px;
        text-align: right;
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #f9ad25;
        text-align: right;
        font-weight: 400;
    }
}
</style>