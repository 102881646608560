import axios from 'axios'
const errorCodeDict = {
    200: '服务器成功返回请求数据',
    201: '新建或修改数据成功',
    202: '一个请求已经进入后台排队(异步任务)',
    204: '删除数据成功',
    400: '发出信息有误',
    401: '用户没有权限(登录过期)',
    403: '用户得到授权，但是访问是被禁止的',
    404: '访问资源不存在',
    406: '请求格式不可得',
    410: '请求资源被永久删除，且不会被看到',
    500: '服务器发生错误',
    502: '网关错误',
    503: '服务不可用，服务器暂时过载或维护',
    504: '网关超时',
}
// 创建一个 axios 实例
const http = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '/prod-api' : '', // 所有的请求地址前缀部分
   
    timeout: 60000, // 请求超时时间毫秒
    withCredentials: true, // 异步请求携带cookie
    headers: {
        // 设置后端需要的传参类型
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
})

// 添加请求拦截器
http.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        // console.log('config', config)
        return config
    },
    function (error) {

        // 对请求错误做些什么

        // console.log(error, '出大问题,快去看看')

        return Promise.reject(error)

    }

)

// 添加响应拦截器
http.interceptors.response.use(
    function (response) {
        // console.log(response, '成功发送请求,真牛批')
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data
        return dataAxios
    },
    function (error) {
        // console.log(error, '出大问题,快去看看')/
        return Promise.reject(error)
    })

export default http
