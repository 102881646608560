<!--  RightConDialogVisible-->
<template>
    <el-dialog v-model="dialogTableVisible" :before-close="beforeClose" custom-class="dialog">
        <div class="serchForm">
            <span style="font-size: 16px; color: #c7e0ec;margin-left: 20px;">状态</span>
            <el-select v-model="selectValue" placeholder="Select" class="serchForm-input">
                <el-option label="全部" value="" />
                <el-option label="进行中" value="0" />
                <el-option label="已完成" value="1" />
            </el-select>
        </div>
        <div class="table">
            <div class="table-title">
                <span> 牵头单位</span>
                <span>重大任务 </span>
                <span> 指标名称 </span>
                <span>责任单位</span>
                <span> 状态</span>
            </div>
            <div class="table-main">
                <div v-for=" item, index in data " :key="index" class="main-item" style="color: floralwhite;">
                    <span :title="item.actionDeptName"> {{ item.actionDeptName }}</span>
                    <span :title="item.actionName"> {{ item.actionName }} </span>
                    <span :title="item.indexName"> {{ item.indexName }} </span>
                    <span :title="item.orgName"> {{ item.orgName }}</span>
                    <span :title="item.doneFlag"> {{ item.doneFlag = '0' ? '进行中' : '已完成' }}</span>
                </div>
            </div>

        </div>


    </el-dialog>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import TestApi from '../../api/TestApi'
const props = defineProps({
    data: Object,
    Visible: Boolean,
    DetId: String
})
const selectValue = ref('')
const dialogTableVisible = ref(false)
const data = ref(null)
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()
const beforeClose = () => {
    emit('update:visible', false);
};


onMounted(() => {
    const params = {
        actionDeptId: props.DetId,
        divisionCode: 330000,
    }
    TestApi.getIndexUnitByDivisionCodeAndDeptId(params).then((res) => {
        data.value = res.data.records
        console.log(data.value[0], '330000330000330000330000330000330000330000330000330000');
    })
    dialogTableVisible.value = props.Visible
})
</script>




<style lang='less' >
.dialog {
    // width: 1920px;
    margin-top: 50px !important;
    height: 80%;
    background-color: rgba(255, 255, 255, 0) !important;
    background: url(../../assets/bg_dialog.png) !important;
    background-size: 100% 100% !important
}
</style>
<style scoped lang="less" >
.dialog {
    .serchForm {
        :deep(.el-input__wrapper) {
            background-color: rgba(0, 0, 0, 0.2);
            margin-left: 10px;
            width: 70px;
        }

        &:deep(.serchForm-input .el-input--suffix .el-input__wrapper) {
            border: #541010 !important
        }

        &:deep(.el-select .el-input__wrapper) {
            box-shadow: none !important;
        }

        &:deep(.el-popper .is-pure .is-light .el-select__popper) {
            font-size: 7px;
            line-height: 19px;
            color: #ff0000;
            font-weight: 200;
            background-color: #003e00;
        }

    }

    .table {
        width: 100%;
        margin-top: 10px;

        .table-title {
            width: 100%;
            display: flex;
            height: 40px;
            background: #102954;

            span {
                font-size: 16px;
                width: 25%;
                text-align: center;
                margin: 0 10px;
                height: 40px;
                width: 25%;
                line-height: 40px;
                text-align: center;
                color: #c7e0ec;

            }
        }

        .table-main {
            width: 100%;

            .main-item {
                height: 40px;
                display: flex;
                background: #102954;
                margin: 10px 0;
            }

            span {
                margin: 0 10px;
                height: 40px;
                width: 25%;
                font-size: 16px;
                line-height: 40px;
                text-align: center;
                color: #c7e0ec;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
<!-- <style  lang="less">
.el-table,
.el-table__expanded-cell {
    background-color: transparent !important
}

.el-table:hover {
    background-color: transparent !important
}

.el-table__expanded-cell:hover {
    background-color: transparent !important
}

.el-table th,
.el-table tr {
    margin: 10px 0!important;
    color: #c7e0ec !important;
    margin: 10px 0  !important;
    background: #102954 !important;
  
}

.el-table__body tr>td {
    background-color: rgba(255, 255, 0, 0) !important;
    font-size: 18px !important;
    height: 60px !important;
    line-height: 60px !important;
}
.el-table__body tr:hover>td {
    background-color: rgba(255, 255, 0, 0) !important;
}
</style> -->