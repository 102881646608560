<template>
  <RouterView />
</template>
<script setup>
import * as echarts from "echarts";
import { provide } from "vue";
import Home from './views/Home.vue'
provide("echarts", echarts);


const getWindowInfo = () => {
  const windowInfo = {
    width: window.innerWidth,
    hight: window.innerHeight
  }
  console.log(windowInfo);
}

const debounce = (getWindowInfo, delay) => {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      getWindowInfo();
    }, delay);
  }
};
const cancalDebounce = debounce(getWindowInfo, 500);

window.addEventListener('resize', cancalDebounce)


</script>
<style lang="less" >
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}


body::-webkit-scrollbar {
  display: none;
}

#app {
  width: 1920px;
  height: 1080px;
  background: url(./assets/u0.jpg) no-repeat center center;
  z-index: 999;
}
</style>

