<!-- taskExecution -->
<template>
    <div id='taskExecution'>
        <div class="title">
            <span>
                成员单位任务执行情况总览
            </span>
        </div>
        <div class="main">
            <div class="main-box" ref="chartDom1"></div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance, inject } from 'vue'
import { useStore } from 'vuex'
import TestApi from '../../api/TestApi'
const props = defineProps({
    data: Object,
    options: {
        type: Object,
        required: true,
        default: null,
    },
})
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()
const chartDom1 = ref(null)
let echarts = inject("echarts"); // 主要
let name = []
const option = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },

    legend: {
        data: ['Desert', 'Wetland'],
        textStyle: {
            color: '#fff',
            fontSize: '20px',
            fontWeight: 700
        }
    },

    xAxis: [
        {
            type: 'category',
            axisTick: { show: false },
            data: [],
            color: '#fff',
            axisLabel: {//x轴文字的配置
                show: true,
                textStyle: {
                    color: "#fff",
                    fontSize: 30
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#fff",
                    width: 0,
                    type: "solid"
                }
            }
        }
    ],
    yAxis: [
        {
            type: 'value',
            axisLine: {//y轴线的颜色以及宽度
                lineStyle: {
                    color: "#fff",
                    width: 12,
                    type: "solid"
                },
            }
        }
    ],
    series: [
        {
            name: 'Desert',
            type: 'bar',
            barWidth:'20%',
            label: {
                show: true
            },
            emphasis: {
                focus: 'series'
            },
            data: [1540, 232, 201, 154, 190],
            stack: 'x'
        },
        {
            name: 'Wetland',
            type: 'bar',
            label: {
                show: true
            },
            emphasis: {
                focus: 'series'
            },
            barWidth:'20%',
            data: [948, 747, 601, 949, 404],
            stack: 'x'
        }
    ]
};
// 基本柱形图
const change = () => {
    const chartBox = echarts.init(chartDom1.value);
    // const dataName = name
    // console.log(dataName);
    chartBox.setOption(option);
    // 根据页面大小自动响应图表大小
    window.addEventListener("resize", function () {
        chartBox.resize();
    });
}
onMounted(() => {
    TestApi.getAllLeadDeptDoneByDivisionCode({ divisionCode: 330000 }).then((res) => {
        for (let index = 0; index < res.data.length; index++) {
            const element = res.data[index];
            option.xAxis[0].data.push(element.name)
        }
        console.log(option.xAxis[0].data);
    }).then(() => {
        change()
    })


})
</script>
<style lang='less' scoped>
#taskExecution {
    height: 100%;

    .title {
        margin: 0, 10px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-family: PangMenZhengDao;
            color: #CFF0FF;
            font-size: 42px;
            text-shadow: 0 0 8px #0075ff;
        }
    }

    .main {
        height: calc(100% - 100px);

        .main-box {
            height: 100%;
        }
    }

}
</style>



