<template>
  <div class="home">
    <div class="home-left">
      <PageLeft @changeShowValue="changeValue"></PageLeft>
    </div>
    <div class="home-middle">
      <taskExcuetion v-if="show"></taskExcuetion>
      <component v-else :is=componentNames[componentIndex] :index="componentIndex" :name='name'></component>
    </div>
    <div class="home-right">
      <PageRight></PageRight>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance } from 'vue'
import taskExcuetion from '../views/taskExecution'
import PageLeft from '../views/HomePage/PageLeft.vue'
import PageMiddle from '../views/HomePage/PageMiddle.vue'
import MiddleCharts1 from './MiddleCharts/MiddleCharts1.vue'
import PageRight from '../views/HomePage/PageRight.vue'
import gauge from '../components/gauge.vue'
import StatusTips from './MiddleCharts/StatusTips.vue'
import category from '../components/category.vue'
import { useStore } from 'vuex'
console.log('taskExcuetion', taskExcuetion)
const props = defineProps({
  data: Object
})
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()
const show = ref(true)
const name = ref('')
const componentIndex = ref(null)
const componentNames = ref([PageMiddle, category, StatusTips])
const changeValue = (data) => {
  componentIndex.value = data.index
  show.value = data.show
  name.value = data.name

}
onMounted(() => { })
</script>
<style lang='less' scoped>
.home {
  height: 100%;
  overflow: hidden;

  .home-left,
  .home-right {
    float: left;
    width: 422px;
    height: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
  }

  .home-middle {
    float: left;
    width: 1076px;
    height: 100%;

  }




}
</style>