<!--  -->
<template>
    <div class='PageLeft'>
        <div class="left-header">
            <div>党委统一战线工作</div>
            <div>领导小组重大任务</div>
        </div>
        <div class="left-btn-box">
            <el-button class="left-btn" round>市本级</el-button>
            <el-button class="left-btn" round>县市区</el-button>
        </div>
        <div class="left-list">
            <div class="list-title">
                <div>序号</div>
                <div>重大任务名称</div>
                <div>执行率</div>
            </div>
            <div class="list-con">
                <LeftListCon class="LeftListCon" v-for="item, index in num" :key="item.index" :index="index"
                    @click="changeValue(index)" :name="item.name" :data="num">
                </LeftListCon>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import LeftListCon from '../PageLeft/LeftListCon.vue'
import TestApi from '../../api/TestApi'
const props = defineProps({
    data: Object
})
const emit = defineEmits(['changeShowValue'])
const { proxy } = getCurrentInstance()
const store = useStore()
const num = ref(null)
const changeValue = (index) => {
    let data = {
        index: index,
        show: false,
        name: num.value[index].name
    }
    emit('changeShowValue', data)
    console.log(num.value[index].name, );
}
onMounted(() => {
    TestApi.getAllActionDoneByDivisionCode({ divisionCode: 330000 }).then((res) => {
        num.value = res.data
    })
})
</script>
<style lang='less' scoped>
.PageLeft {
    height: 100%;
    margin: 120px 0 0 20px;

    .left-header {
        margin: 20px;
        background: url(../../assets/left_list_title.png) no-repeat;
        background-size: 100% 100%;

        div {
            font-family: PingFangSC-Medium;
            font-size: 24px;
            color: #cff0ff;
            text-align: left;
            font-weight: 500;
            padding-left: 26px;
        }
    }

    .left-btn-box {
        display: flex;
        justify-content: space-evenly;

        .left-btn {
            cursor: pointer;
            background-color: rgba(6, 184, 255, 0.404);
            border-width: 0px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
        }

        .left-btn:active {
            background-image: linear-gradient(180deg, #2DC4FF 0%, #1492FF 100%);
        }
    }

    .left-list {
        height: 90%;

        .list-title {
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            font-family: PingFangSC-Medium;
            font-size: 20px;
            color: rgba(230, 247, 255, .65);
            font-weight: 500;
            line-height: 50px;
            padding: 0 8px;
            box-sizing: border-box;

            div {
                text-align: left;
                float: left;
                height: 50px;
            }

            div:nth-child(1) {
                width: 20%;
                text-align: center;

            }

            div:nth-child(2) {
                width: 60%;
                text-align: center;

            }

            div:nth-child(3) {
                width: 20%;
                text-align: center;

            }
        }

        .list-con {
            width: 100%;
            height: calc(100% - 230px);
            overflow: auto;
        }

        .list-con::-webkit-scrollbar {
            display: none;
        }
    }

    .LeftListCon:hover {
        background: url(../../assets/bg_item_selected.png) no-repeat !important;
        background-size: 100% 100% !important;

    }

}
</style>