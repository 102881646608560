<!--  -->
<template>
    <div class='PageRight'>
        <div class="Right-header">
            <p>牵头单位</p>
        </div>
        <div class="Right-list">
            <div class="list-title">
                <div>单位名称</div>
                <div>指标数</div>
                <div>执行进度</div>
            </div>
            <div class="list-con">
                <RightListCon class="RightListCon" v-for="item, index in num" :key="item.index" :index="index"
                    :name="item.name" :data="num" @click="chengeVisiable(item)" :chengeVisiable="RightConDialogVisible">
                </RightListCon>
            </div>

            <template v-if="RightConDialogVisible">
                <RightConDialog v-model:Visible="RightConDialogVisible" @update:visible="RightConDialogVisible = $event"
                    :DetId="DetId" >
                </RightConDialog>
            </template>

        </div>
    </div>
</template>
<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import RightListCon from '../PageRight/RightListCon.vue'
import RightConDialog from '../PageRight/RightConDialog.vue'
import TestApi from '../../api/TestApi'
const props = defineProps({
    data: Object
})
let RightConDialogVisible = ref(false)
const DetId = ref('')
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()
const num = ref(null)
const chengeVisiable = (item) => {
    console.log(item);
    DetId.value = item.id
    RightConDialogVisible.value = true
}
onMounted(() => {
    TestApi.getAllLeadDeptDoneByDivisionCode({ divisionCode: 330000 }).then((res) => {
        console.log(res,'res');
        num.value = res.data
    })
})
</script>
<style lang='less' scoped>
.PageRight {
    height: 100%;
    margin: 120px 20px 0 0px;

    .Right-header {
        font-family: YouSheBiaoTiHei;
        font-size: 26px;
        color: #CFF0FF;
    }

    .Right-list {
        height: 100%;

        .list-title {
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            font-family: PingFangSC-Medium;
            font-size: 20px;
            color: rgba(230, 247, 255, .65);
            font-weight: 500;
            line-height: 50px;
            padding: 0 8px;
            box-sizing: border-box;

            div {
                float: left;
                height: 50px;
            }

            div:nth-child(1) {
                width: 40%;
                text-align: left;

            }

            div:nth-child(2) {
                width: 30%;
                text-align: center;

            }

            div:nth-child(3) {
                width: 30%;
                text-align: right;

            }
        }

        .list-con {
            width: 100%;
            height: calc(100% - 280px);
            overflow: auto;
        }

        .list-con::-webkit-scrollbar {
            display: none;
        }
    }

}
</style>