<!--PageMiddle  -->
<template>
    <div class='PageMiddle'>
        <div class="main">
            <div class="main-title">
                <span>
                    {{ props.name }}
                </span>
            </div>
            <div class="main-inner">
                <component v-for=" item, index in num" :key="index" :value="item.value" :title='item.name'
                    :is=componentNames[props.index] class="main-gauge">
                </component>
                <!-- <category class="main-gauge1"></category>
                <StatusTips></StatusTips> -->
            </div>
        </div>

    </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted, watch, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import gauge from '../../components/gauge.vue'
import taskExecution from '../taskExecution'
import inputOption from '../../components/options'
import category from '../../components/category'
import StatusTips from '../MiddleCharts/StatusTips.vue'
const props = defineProps({
    data: Object,
    index: {
        type: Number,
    },
    name: {
        type: String,
    }
})
const componentNames = ref([gauge, category, StatusTips])
const emit = defineEmits(['update'])
const { proxy } = getCurrentInstance()
const store = useStore()

const num = [{
    name: '组件1',
    value: 10,
}, {
    name: '组件2',
    value: 11,
}, {
    name: '组件3',
    value: 20,
}, {
    name: '组件4',
    value: 30,
}, {
    name: '组件5',
    value: 23,
}, {
    name: '组件6',
    value: 54,
}, {
    name: '组件7',
    value: 88,
}]
onMounted(() => {
})

</script>
<style lang='less' scoped>
.PageMiddle {
    height: 1080px;
    display: flex;
    align-items: center;
    justify-content: center;

    .main {
        width: 100%;
        height: 80%;
        background-image: linear-gradient(#0c2968, #0c296800);
        border: 1px #0f317e solid;
        border-radius: 2% 2% 0 0;
        box-shadow: 0px 0px 10px 0px #0075ff;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;

        .main-title {
            width: 100%;
            margin: 0, 10px;
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-family: PangMenZhengDao;
                color: #CFF0FF;
                font-size: 42px;
                text-shadow: 0 0 8px #0075ff;
            }
        }

        .main-inner {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;

        }

        .main-gauge {
            width: 33.33%;
            height: 33.33%;
        }

        .main-gauge1 {
            width: 66.00%;
            height: 33.33%;
        }

    }

    .main::-webkit-scrollbar {
        display: none;
    }

}
</style>
